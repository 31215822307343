@import '../../../../scss/theme-bootstrap';

.sticky-footer-tout {
  text-align: center;
  vertical-align: middle;
  z-index: 1;
  text-transform: none;
  position: relative;
  border-#{$ldirection}: 1px solid $color-off-black;
  min-height: 200px;
  width: 100%;
  &__content {
    height: 100%;
    padding-top: 25px;
  }
  &__title {
    font-size: 20px;
    text-align: center;
    padding: 5px 35px;
    font-weight: 700;
  }
  &__copy {
    font-size: 14px;
    line-height: get-line-height(14px, 22px);
    padding: 5px 30px;
  }
  &__image img {
    width: 100%;
  }
  &__content-ctas a {
    padding: 5px 15px;
  }
  &__content-image {
    transform: translateY(-50%);
    align-self: center;
    position: absolute;
    top: 50%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    height: auto;
  }
}
